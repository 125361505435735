import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DoneIcon from '@mui/icons-material/Done';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PaymentsIcon from '@mui/icons-material/Payments';
import { animateScroll as scroll } from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close';

import { cancelOrder, getAllCities, getAllPlans, getAllServices, getAllServicesByCarWashPoint, getAvailableDatesByCarWash, getAvailableSlotsByDateAndPackageAndCarWashPointAndOrderType, getCarTypes, getCarTypesByServiceAndCarWashPoint, getCarWashPointLocationsAPI, getCarWashPointsByCityAPI, getCustomerDetailsByPhoneNumberAndPlateNumber, getExtraServicesBySeriveAndCarWashPointAndCarTypeId, getPackagesByMainServiceByCarWashPointByCarType, loginBusiness, postOrder, registerABusiness } from "../../services/axios";
import './business.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-phone-number-input/style.css'
import { Link } from "react-router-dom";
import { AppConstants } from "../../shared";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";

const localizer = momentLocalizer(moment)

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Business = ({ settings }) => {
  const [cities, setCities] = useState([]);
  const [carWashPoints, setCarWashPoints] = useState([]);
  const [mainServices, setMainServices] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [extraServices, setExtraServices] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCarWashPoint, setCarWashPoint] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedCarType, setSelectedCarType] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedCarWashLocation, setSelectedCarWashLocation] = useState('');
  const [selectedExtraServices, setSelectedExtraServices] = useState([]);
  const [carWashLocations, setCarWashLocations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSlot, setShowSlot] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [plateNumber, setPlateNumber] = useState('');
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    payment_method: ''
  });
  const [customerExists, setCustomerExists] = useState(false);
  const [showCustomerInfor, setShowCustomerInfo] = useState(false);
  const [packagePrice, setPackagePrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderResponse, setOrderResponse] = useState({});
  const [businessInfor, setBusinessInfor] = useState({
    "id": null,
    "customer_name": "",
    "customer_email": "",
    "customer_type": "",
    "customer_phone_number": "",
    "company_details": {
      "id": 1,
      "company_name": "",
      "organization_number": "",
      "vat_number": "",
      "invoice_address": "",
      "company_email": ""
    },
    "password": null,
    agree: false
  });
  const [value, setValue] = useState(0);
  const [businessInforDB, setBusinessInforDB] = useState({
    "id": null,
    "username": null,
    "customer_name": "",
    "customer_email": "",
    "email_verified_at": null,
    "customer_type": "",
    "customer_phone_number": "",
    "customer_vehicle_plate_number": null,
    "company_details": {
      "id": 1,
      "fk_customer_id": null,
      "company_name": "",
      "organization_number": "",
      "vat_number": "",
      "invoice_address": "",
      "company_email": ""
    }
  });
  const [loginInfor, setLoginInfor] = useState({ email: '', password: '', agree: false, emailRequiredError: false, emailNotValidError: false, passwordError: false, agreeError: false });
  const [proceedToCalendar, setproceedToCalendar] = useState(false);
  const [customerType, setCustomerType] = useState('Företagskund');
  const [inviteUrl, setInviteUrl] = useState(null);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [toggleSnackBar, setToggleSnackBar] = useState(null);
  const [showPrivacyPolicay, setShowPrivacyPolicay] = useState(false);
  const [callingApi, setCallingApi] = useState(false);
  const { t } = useTranslation();



  const handleCityChange = async (event) => {
    setSelectedCity(event.target.value);

    await getCarWashPointsByCity(event.target.value);
  };

  const handleCarWashPointChange = async (event) => {
    setCarWashPoint(event.target.value);

    await getMainServices(event.target.value);
    await getCarWashPointLocations(event.target.value);
  };

  const handleCarWashPointLocationChange = async (event) => {
    setSelectedCarWashLocation(event.target.value);

    await getMainServices();
  };

  const handleMainServiceChange = async (event) => {
    setSelectedService(event.target.value);

    await getAllCarTypes(event.target.value);
  };

  const handleCarTypeClick = async (id) => {
    setSelectedCarType(id);

    await getPackages(id);
    await getExtraServices(id);
  };

  const handlePackageChange = async (id) => {
    setSelectedPackage(id);
  };

  const handleExtraServicesClick = async (x) => {
    const selectedExtraServicesCopy = [...selectedExtraServices];
    const priceToAdjust = x.car_types[0].price;

    if (selectedExtraServicesCopy.includes(x.id)) {
      selectedExtraServicesCopy.splice(selectedExtraServices.indexOf(x.id), 1);

      setServicePrice(servicePrice - priceToAdjust);
      availableDates.forEach(x => {
        x.title = (packagePrice + (servicePrice - priceToAdjust)) + ' SEK'
      })
    } else {
      selectedExtraServicesCopy.push(x.id);
      setServicePrice(servicePrice + priceToAdjust);

      availableDates.forEach(x => {
        x.title = (packagePrice + (servicePrice + priceToAdjust)) + ' SEK'
      })
    }

    setSelectedExtraServices([...selectedExtraServicesCopy]);
  };

  const handleAvailableSlotClick = async (slot) => {
    setSelectedSlot(slot);
    setShowSlot(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);

    if (value === 0) {
      setTimeout(() => {
        if (window.grecaptcha && window.grecaptcha.render) {
          window.grecaptcha.render(document.getElementById('signup'), {
            'sitekey': '6Lel83spAAAAAINnk6ugkBGiVGqpeg4w0lHC02ib'
          });
        }
      }, 1000);
    } else {
      setTimeout(() => {
        if (window.grecaptcha && window.grecaptcha.render) {
          window.grecaptcha.render(document.getElementById('login'), {
            'sitekey': '6Lel83spAAAAAINnk6ugkBGiVGqpeg4w0lHC02ib'
          });
        }
      }, 1000);
    }

  };

  const getCarWashPointsByCity = async (cityId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
      const res = await getCarWashPointsByCityAPI(cityId, 'b2b');

      if (res && res.length) {
        if (res.length === 1) {
          setCarWashPoint(res[0].id);

          await getCarWashPointLocations(res[0].id)

          await getMainServices(res[0].id);
        }

        setCarWashPoints(res);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getMainServices = async (carWashId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
      const res = await getAllServicesByCarWashPoint(carWashId ? carWashId : selectedCarWashPoint);

      if (res && res.length) {
        setMainServices(res);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getAllCarTypes = async (serviceId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');

      const res = await getCarTypesByServiceAndCarWashPoint(serviceId, selectedCarWashPoint);

      if (res && res.length) {
        setCarTypes(res);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getPackages = async (carTypeId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
      const res = await getPackagesByMainServiceByCarWashPointByCarType(selectedService, selectedCarWashPoint, carTypeId);

      if (res && res.packages && res.packages.length) {
        setPackages(res.packages);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());

      console.log(error, 'error');
    }
  }

  const getExtraServices = async (carTypeId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');

      const res = await getExtraServicesBySeriveAndCarWashPointAndCarTypeId(selectedService, selectedCarWashPoint, carTypeId);

      if (res && res.extra_services && res.extra_services.length) {
        setExtraServices(res.extra_services);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getAvailableDates = async (packagePrice) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
      const res = await getAvailableDatesByCarWash(selectedCarWashPoint);

      if (res && res.available_dates && res.available_dates.length) {

        res.available_dates = res.available_dates.map(x => {
          return {
            allDay: false,
            title: (packagePrice + servicePrice) + ' Kr',
            start: new Date(x),
            end: new Date(x),
            resource: 'Gmail',
          }
        });

        if (res && res.holidays && res.holidays.length) {
          res.holidays.map(x => {
            res.available_dates.push({
              allDay: true,
              title: x.name,
              start: new Date(x.date),
              end: new Date(x.date),
              resource: 'Gmail'
            });
          });
        }

        setAvailableDates(res.available_dates);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getAvailablSlots = async (date) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
      const dateCopy = new Date(date);
      const dateToServer = dateCopy.getFullYear() + '-' + (dateCopy.getMonth() + 1) + '-' + dateCopy.getDate();

      const res = await getAvailableSlotsByDateAndPackageAndCarWashPointAndOrderType(dateToServer, selectedPackage, selectedCarWashPoint, 'b2b', selectedCarType);

      if (res && res.available_time_slots && res.available_time_slots.length) {

        setAvailableSlots(res.available_time_slots);

        scroll.scrollToBottom();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      console.log(error, 'error');
    }
  }

  const getCarWashPointLocations = async (carWashPointId) => {
    try {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');

      const res = await getCarWashPointLocationsAPI(carWashPointId);

      if (res && res.locations && res.locations.length === 1) {
        setSelectedCarWashLocation(res.locations[0].id);
      }

      setCarWashLocations(res.locations);

      scroll.scrollToBottom();
    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());

      console.log(error, 'error');
    }
  }

  const bookAppointment = async () => {
    setSeverity('success');
    setToggleSnackBar(false);
    setMessage('');
    setCallingApi(true);



    const dateCopy = new Date(selectedDate);
    const dateToServer = dateCopy.getFullYear() + '-' + (dateCopy.getMonth() + 1) + '-' + dateCopy.getDate();
    const body = {
      "city_id": selectedCity,
      "car_type_id": selectedCarType,
      "service_id": selectedService,
      "car_wash_point_id": selectedCarWashPoint,
      "payment_method": 'pay_at_place',
      "car_wash_location_id": selectedCarWashLocation,
      "is_b2c": false,
      "is_b2b": true,
      "date": dateToServer,
      "total_price": (packagePrice + servicePrice),
      "customer_details": {
        "b2b_customer_id": businessInforDB.id,
        "email": businessInforDB.customer_email,
        "name": businessInforDB.company_details.company_name,
        "phone_number": businessInforDB.customer_phone_number,
        "car_plate_number": plateNumber
      },
      "order_items": {
        "packages": [],
        "extra_services": []
      }
    };

    if (selectedPackage) {
      const objPackageToSend = {
        package_id: selectedPackage,
        car_type: selectedCarType,
        date: dateToServer,
        start_time: selectedSlot,
        price: packagePrice,
        quantity: 1
      }

      body.order_items.packages.push(objPackageToSend);
    }

    if (selectedExtraServices && selectedExtraServices.length) {

      selectedExtraServices.forEach(x => {
        const extraService = extraServices.find(y => y.id === x);
        const objExtraServicesToSend = {
          extra_service_id: x,
          car_type: selectedCarType,
          date: dateToServer,
          price: extraService.car_types[0].price,
          quantity: 1
        }

        body.order_items.extra_services.push(objExtraServicesToSend);
      })

    }

    try {
      const res = await postOrder(body);

      if (res && res.order) {
        setOrderResponse(res.order);
        prepareIcsFile(res.order.event_details.startsAt, res.order.event_details.endsAt, 'Car Service Booking', res.order.event_details.address, res.order.event_details.address)
        setShowSuccess(true);
        setCallingApi(false);
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());
      setCallingApi(false);

      console.log(error, 'error');
    }
  }

  const registerBusiness = async () => {
    setSeverity('success');
    setToggleSnackBar(false);
    setMessage('');
    if (window.grecaptcha) {

      if (window.grecaptcha.getResponse()) {
        const body = {
          "email": businessInfor.customer_email,
          "password": businessInfor.password,
          "company_name": businessInfor.company_details.company_name,
          "organization_number": businessInfor.company_details.organization_number,
          "vat_number": businessInfor.company_details.vat_number,
          "invoice_address": businessInfor.company_details.invoice_address,
          "phone_number": businessInfor.customer_phone_number,
          "reference_person_name": businessInfor.reference_person_name,
          "reference_person_number": businessInfor.reference_person_number
        };

        try {
          const res = await registerABusiness(body);

          if (res && res.customer) {
            setBusinessInforDB(res.customer)

            setSeverity('success');
            setToggleSnackBar(true);
            setMessage(t('successOfRegisteration'));

            scroll.scrollToBottom();

          }

        } catch (error) {
          setSeverity('error');
          setToggleSnackBar(true);
          setMessage(error.toString());

          console.log(error, 'error');
        }
      } else {
        alert(t('verifyCaptcha'))
      }
    }

  }

  const LoginBusiness = async () => {
    setSeverity('success');
    setToggleSnackBar(false);
    setMessage('');
    if (window.grecaptcha) {

      if (window.grecaptcha.getResponse()) {
        const body = {
          "email": loginInfor.email,
          "password": loginInfor.password,
        };

        try {
          const res = await loginBusiness(body);

          if (res && res.customer) {
            setBusinessInforDB(res.customer);

            setSeverity('success');
            setToggleSnackBar(true);
            setMessage(t('loggedInSuccess'));

            scroll.scrollToBottom();

          }

        } catch (error) {
          setSeverity('error');
          setToggleSnackBar(true);
          setMessage(error.toString());
        }
      } else {
        alert(t('verifyCaptcha'));
      }
    }


  }

  const cancelAppointment = async (id) => {
    setSeverity('success');
    setToggleSnackBar(false);
    setMessage('');
    const body = {
      "order_id": id
    };


    try {
      const res = await cancelOrder(body);

      if (res && res.message) {
        setSeverity('success');
        setToggleSnackBar(true);
        setMessage(res.message);

        window.location.reload();
      }

    } catch (error) {
      setSeverity('error');
      setToggleSnackBar(true);
      setMessage(error.toString());

      console.log(error, 'error');
    }
  }

  const prepareIcsFile = (dateStart, dateEnd, summary, description, location) => {
    const content = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:car-wash-system
BEGIN:VTIMEZONE
TZID:Europe/Stockholm
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
END:STANDARD
BEGIN:DAYLIGHT
DTSTART:${dateStart}
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
END:DAYLIGHT
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
END:STANDARD
END:VTIMEZONE
BEGIN:VTIMEZONE
TZID:UTC
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0000
TZOFFSETTO:+0000
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${dateStart}
SUMMARY:${summary}
DESCRIPTION:${description}
DTSTART;TZID=Europe/Stockholm:${dateStart}
DTEND;TZID=Europe/Stockholm:${dateEnd}
END:VEVENT
END:VCALENDAR
LOCATION:${location}`;

    var data = new File([content], { type: "text/plain" });

    const icsFile = window.URL.createObjectURL(data);

    setInviteUrl(icsFile);

    return icsFile;
  }

  useEffect(() => {
    try {
      (async () => {
        const res = await getAllCities();

        //
        if (res && res.length) {
          if (res.length === 1) {
            setSelectedCity(res[0].id);

            await getCarWashPointsByCity(res[0].id);
          }

          setCities(res);
        }
      })();
    } catch (error) {
      console.log(error, 'error');
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (window.grecaptcha && window.grecaptcha.render) {
        window.grecaptcha.render(document.getElementById('login'), {
          'sitekey': '6Lel83spAAAAAINnk6ugkBGiVGqpeg4w0lHC02ib'
        });
      }
    }, []);

  });



  return <>
    <div style={{ justifyContent: 'center' }}>
      {!businessInforDB.id && <div style={{ flexDirection: 'row', display: 'flex', padding: 20, justifyContent: 'center' }}>
        <h2 style={{ textAlign: 'center' }} > {t('selectCustomerType')} </h2>
      </div>}

      {!businessInforDB.id && <div style={{ flexDirection: 'row', display: 'flex', padding: 20, justifyContent: 'center' }}>
        <Link to="/" style={{ color: '#0288d1', textDecoration: 'none' }} ><Card variant="outlined" onClick={() => {
          setCustomerType('Privatkund')
        }} className={customerType === 'Privatkund' ? 'selected_customer_type' : 'customer_type'} style={{ marginRight: 20 }} >
          <CardContent>
            <h4 style={{ color: '#0288d1' }} > {t('privateCustomer')} </h4>
          </CardContent>
        </Card></Link>
        <Card onClick={() => {
          setCustomerType('Företagskund')
        }} className={customerType === 'Företagskund' ? 'selected_customer_type' : 'customer_type'} variant="outlined">
          <CardContent>
            <h4 style={{ color: '#0288d1', textDecoration: 'none' }} > {t('businessCustomer')} </h4>
          </CardContent>
        </Card>
      </div>}

      {!businessInforDB.id && !showSuccess && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} > <Tabs style={{ padding: 20, justifyContent: 'center', height: 'fit-content' }} value={value} onChange={handleTabChange} aria-label="disabled tabs example">
        <Tab label={t('login')} />
        <Tab label={t('signUp')} />
      </Tabs></div>}


      {!businessInforDB.id && value === 0 && <div>
        <form
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
          }}
          onSubmit={(e) => {
            e.preventDefault();
            LoginBusiness();
          }}
          style={{ textAlign: 'center' }}
        >
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('email')}
              type="email"
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setLoginInfor({
                  ...loginInfor,
                  email: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('password')}
              type="password"
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setLoginInfor({
                  ...loginInfor,
                  password: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'center', width: '100%' }} >
            <FormControlLabel control={<Checkbox required checked={loginInfor.agree} onClick={(e) => {
              setLoginInfor({
                ...loginInfor,
                agree: e.target.checked
              })
            }} />} label={``} /> <span style={{ marginTop: 7 }} > {t('agreePP')} {settings.company_name.trim()}{t('s')} <span onClick={() => {
              setShowPrivacyPolicay(true);
            }} style={{ color: '#0288d1', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }} >{t("PPTitle")}.</span> </span>
          </div>

          <div style={{ padding: 10, textAlign: 'center !important' }}>
            <div id="login" className="g-recaptcha" ></div>
          </div>
          <div style={{ padding: 10 }}>
            <Button type="submit" style={{ marginLeft: 7 }} variant="contained" size="large"> {t('login')} </Button>
          </div>
        </form>
      </div>}

      {!businessInforDB.id && value === 1 && <div>
        <form
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          onSubmit={(e) => {
            e.preventDefault();
            registerBusiness();
          }}
          style={{ textAlign: 'center' }}
        >
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('email')}
              type="email"
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  customer_email: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('password')}
              type="password"
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  password: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('companyName')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  company_details: {
                    ...businessInfor.company_details,
                    company_name: e.target.value
                  }
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('organisatonNumber')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  company_details: {
                    ...businessInfor.company_details,
                    organization_number: e.target.value
                  }
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('vatNumber')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  company_details: {
                    ...businessInfor.company_details,
                    vat_number: e.target.value
                  }
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('invoiceNumber')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  company_details: {
                    ...businessInfor.company_details,
                    invoice_address: e.target.value
                  }
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('phoneNumber')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  customer_phone_number: e.target.value
                })
              }}
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('referrencePersonName')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  reference_person_name: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              required
              label={t('referrencePersonPhoneNumber')}
              style={{ margin: 10, width: 305 }}
              onChange={(e) => {
                setBusinessInfor({
                  ...businessInfor,
                  reference_person_number: e.target.value
                })
              }}
              variant="outlined"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'center', width: '100%' }} >
            <FormControlLabel control={<Checkbox required checked={businessInfor.agree} onClick={(e) => {
              setBusinessInfor({
                ...businessInfor,
                agree: e.target.checked
              })
            }} />} label={``} /> <span style={{ marginTop: 7 }} > {t('agreePP')} {settings.company_name.trim()}{t('s')} <span onClick={() => {
              setShowPrivacyPolicay(true);
            }} style={{ color: '#0288d1', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }} >{t("PPTitle")}.</span> </span>
          </div>

          <div style={{ padding: 20 }} >
            <div id="signup" className="g-recaptcha" ></div>
          </div>
          <div style={{ padding: 10 }}>
            <Button type="submit" style={{ marginLeft: 7 }} variant="contained" size="large"> {t('register')} </Button>
          </div>
        </form>
      </div>}

      <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', padding: 20, justifyContent: 'center' }}>
        {businessInforDB.id && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectCustomerType')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }}  > </span></div>
          <p className="selected_date" onClick={() => { }} > {customerType} </p>
        </div>}

        {selectedCity && cities.length > 1 && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedCity')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedCity('');
            setCarWashPoint('');
            setSelectedCarWashLocation('');
            setSelectedService('');
            setSelectedCarType('');
            setSelectedPackage('');
            setExtraServices([]);
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setServicePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setMainServices([]);
            setCarTypes([]);
            setPackages([]);
            setExtraServices([]);
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {cities.find(x => x.id === selectedCity).title}</p>
        </div>}

        {selectedCarWashPoint && carWashPoints.length > 1 && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedCarWashPoint')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setCarWashPoint('');
            setSelectedCarWashLocation('');
            setSelectedService('');
            setSelectedCarType('');
            setSelectedPackage('');
            setExtraServices([]);
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setServicePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setMainServices([]);
            setCarTypes([]);
            setPackages([]);
            setExtraServices([]);
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {carWashPoints.find(x => x.id === selectedCarWashPoint).name}</p>
        </div>}

        {selectedCarWashLocation && carWashLocations.length > 1 && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedCarWashLocation')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedCarWashLocation('');
            setSelectedService('');
            setSelectedCarType('');
            setSelectedPackage('');
            setExtraServices([]);
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setServicePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setMainServices([]);
            setCarTypes([]);
            setPackages([]);
            setExtraServices([]);
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {carWashLocations.find(x => x.id === selectedCarWashLocation).name}</p>
        </div>}

        {selectedService && mainServices.length > 0 && selectedCarWashLocation && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedService')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={async () => {
            setSelectedService('');
            setSelectedCarType('');
            setSelectedPackage('');
            setExtraServices([]);
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setServicePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setCarTypes([]);
            setPackages([]);
            setExtraServices([]);
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
            await getMainServices();
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {mainServices.find(x => x.id === selectedService).name}</p>
        </div>}

        {selectedCarType && carTypes.length > 0 && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedCarType')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={async () => {
            setSelectedCarType('');
            setSelectedPackage('');
            setExtraServices([]);
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setServicePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setPackages([]);
            setExtraServices([]);
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
            await getCarTypes();
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {carTypes.find(x => x.id === selectedCarType).name}</p>
        </div>}

        {selectedPackage && packages.length > 0 && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedPackage')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedPackage('');
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setSelectedSlot('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setPackagePrice(0);
            setShowSuccess(false);
            setOrderResponse({});
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(false);
            setShowCalendar(false);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => { }} > {packages.find(x => x.id === selectedPackage).title}</p>
        </div>}

        {selectedExtraServices.length > 0 && (showCalendar || selectedDate) && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedExtraServices')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedDate('');
            setSelectedSlot('');
            setPhoneNumber('');
            setPlateNumber('');
            setCustomer({
              name: '',
              email: '',
              payment_method: ''
            });
            setCustomerExists(false);
            setShowCustomerInfo(false);
            setShowSuccess(false);
            setOrderResponse({});
            setAvailableDates([]);
            setAvailableSlots([]);
            setproceedToCalendar(true);
            setSelectedExtraServices([])
            setShowCalendar(false);
            setServicePrice(0)
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" style={{ fontSize: 15, marginTop: 25 }} onClick={() => { }} > {selectedExtraServices.map((x) => <span onClick={() => { }} > {extraServices.find(y => y.id === x).title}, </span>)}</p>


        </div>}

        {selectedDate && <div style={{ width: 296, marginRight: 30 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedDate')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedDate(null)
            setShowCalendar(true);
            setAvailableSlots([]);
            setShowSlot(false);
            setSelectedSlot(null);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => {
            setSelectedDate(null)
            setShowCalendar(true);
            setAvailableSlots([]);
            setShowSlot(false);
            setSelectedSlot(null);
          }} > {AppConstants.weekDays[selectedDate.getDay() - 1]}, {AppConstants.months[new Date(selectedDate).getMonth()]} {new Date(selectedDate).getDate()}, {new Date(selectedDate).getFullYear()} </p>
        </div>}

        {selectedSlot && <div style={{ width: 296 }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> <p> {t('selectedSlot')} </p> <span style={{ marginTop: 15, marginLeft: 77, cursor: 'pointer' }} onClick={() => {
            setSelectedSlot(null)
            setShowSlot(true);
          }} > <EditIcon color="info" /> </span></div>

          <p className="selected_date" onClick={() => {
            setSelectedSlot(null)
            setShowSlot(true);
          }} > {selectedSlot}</p>
        </div>}
      </div>
    </div>

    {businessInforDB.id && <div>
      <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} >
        {cities && cities.length > 1 && !selectedCity && <Box style={{ padding: 20, justifyContent: 'center' }} sx={{ minWidth: 300 }}>
          <FormControl color="info" size='medium' variant='filled' style={{ width: 300 }}>
            <InputLabel> {t('selectCity')} </InputLabel>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
            >
              {cities.map(x => <MenuItem key={x.id} value={x.id}>{x.title}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}

        {carWashPoints && carWashPoints.length > 1 && !selectedCarWashPoint && <Box style={{ padding: 20, justifyContent: 'center' }} sx={{ minWidth: 300 }}>
          <FormControl color="info" size='medium' variant='filled' style={{ width: 300 }}>
            <InputLabel> {t('selectCarWashPoint')} </InputLabel>
            <Select
              value={selectedCarWashPoint}
              onChange={handleCarWashPointChange}
            >
              {carWashPoints.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}

        {carWashLocations && carWashLocations.length > 1 && !selectedCarWashLocation && <Box style={{ padding: 20, justifyContent: 'center' }} sx={{ minWidth: 300 }}>
          <FormControl color="info" size='medium' variant='filled' style={{ width: 300 }}>
            <InputLabel> {t('selectCarWashLocation')} </InputLabel>
            <Select
              value={selectedCarWashLocation}
              onChange={handleCarWashPointLocationChange}
            >
              {carWashLocations.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}

        {mainServices && mainServices.length > 0 && !selectedService && <Box style={{ padding: 20, justifyContent: 'center' }} sx={{ minWidth: 300 }}>
          <FormControl color="info" size='medium' variant='filled' style={{ width: 300 }}>
            <InputLabel> {t('selectServiceType')} </InputLabel>
            <Select
              value={selectedService}
              onChange={handleMainServiceChange}
            >
              {mainServices.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}

      </div>

      {carTypes && carTypes.length > 0 && !selectedCarType && <div>
        <h2 style={{ textAlign: 'center' }} > {t('selectCarType')} </h2>
      </div>}

      <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} >
        {carTypes && carTypes.length > 0 && !selectedCarType && carTypes.map(x => {
          return <span key={x.id} className={x.id === selectedCarType ? 'selected_car_type' : 'car_type'} onClick={() => handleCarTypeClick(x.id)}>
            <img
              alt={x.name}
              src={x.image_path}
              className="car_image"
            />
            <span className="caption" >{x.name}</span>
          </span>
        })}
      </div>

      {packages && packages.length > 0 && !selectedPackage && <div>
        <h2 style={{ textAlign: 'center' }} > {t('selectPackage')} </h2>
      </div>}


      <div className="packages_div" >
        {packages && packages.length > 0 && !selectedPackage && packages.map(x => <div key={x.id} className={x.id === selectedPackage ? 'selected_package' : 'package'} onClick={async () => {
          handlePackageChange(x.id);
          setPackagePrice(x.price);
          setproceedToCalendar(true);

          if (extraServices && extraServices.length === 0) {
            setShowCalendar(true);
            setproceedToCalendar(false);
            await getAvailableDates(x.price);
          }

        }} sx={{ minWidth: 260 }} style={{ width: 260, marginRight: 20, textAlign: 'center', margin: 10 }} variant="outlined">
          <div>
            <h2 style={{ color: '#0288d1' }} >{x.title}</h2>
            <h2 style={{ color: '#0288d1', margin: 0 }} >{t('price')}: {x.price}Kr.  </h2>
            <span style={{ color: '#0288d1', fontWeight: 'bold' }}>{t('duration')}: {x.duration} {t('mins')}</span>

            <br></br>
            {x.description && x.description.length > 0 && x.description.map(s => {
              return <div style={{ display: 'flex', flexDirection: 'row', padding: 5 }} >
                <DoneIcon color="info" style={{ marginRight: 10, fontSize: 30 }} />
                <div style={{ textAlign: 'initial' }} dangerouslySetInnerHTML={{ __html: s }} />
              </div>
            })}
          </div>
          <div style={{ justifyContent: 'center' }} >
            <Button size="small" color="success" variant="contained"> {t("select")} </Button>
          </div></div>)}
      </div>
      {extraServices && extraServices.length > 0 && showCalendar === false && !selectedDate && <div>
        <h2 style={{ textAlign: 'center' }} > {t("selectExtraServices")} </h2>
      </div>}

      <div style={{ display: 'flex', flexDirection: 'row', padding: 20, flexWrap: 'wrap', justifyContent: 'center' }} >
        {extraServices && extraServices.length > 0 && showCalendar === false && !selectedDate && extraServices.map(x => <div key={x.id} className={selectedExtraServices.includes(x.id) ? 'selected_service' : 'service'} onClick={() => handleExtraServicesClick(x)} variant="outlined" sx={{ minWidth: 345 }}>
          <div>
            {x.icon_path && <img src={x.icon_path} alt="service_logo" style={{ maxWidth: 100, maxHeight: 100 }} />}
            <h2 style={{ color: '#0288d1' }} >{x.title}</h2>
            <h2 style={{ color: '#0288d1', margin: 0 }} >{t('price')}: {x.price} {x.car_types[0].price}Kr.  </h2>
            {x.description && <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: x.description }} />}
          </div>
          <div style={{ justifyContent: 'center', paddingBottom: 10 }} >
            <Button size="small" color={selectedExtraServices.includes(x.id) ? 'info' : 'success'} variant="contained"> {t("select")} </Button>
          </div></div>)}
      </div>

      {proceedToCalendar && !selectedDate && <div style={{ display: 'flex', flexDirection: 'row', padding: 20, flexWrap: 'wrap', justifyContent: 'center' }} >
        <Button size="large" onClick={async () => {
          setShowCalendar(true);
          setproceedToCalendar(false);
          await getAvailableDates(packagePrice);
        }} color="info" variant="contained"> {t('nextButton')} </Button>
      </div>}

      {selectedPackage && showCalendar && <div>
        <h2 style={{ textAlign: 'center' }} > {t('calendarHeading')} </h2>
      </div>}

      <div className="calendar_div">
        {selectedPackage && showCalendar && <Calendar
          localizer={localizer}
          events={availableDates}
          style={{ height: 500 }}
          onSelectEvent={async (e) => {
            if (!e.allDay) {
              setSelectedDate(e.start)
              await getAvailablSlots(e.start);
              setShowSlot(true);
              setShowCalendar(false);
            } else {
              alert(`It's a holiday due in account of ${e.title}`);
            }
          }}
          messages={{
            day: t('day'),
            week: t('week'),
            month: t('month'),
            previous: t('previous'),
            next: t('next'),
            yesterday: t('yesterday'),
            tomorrow: t('tomorrow'),
            today: t('today'),
            agenda: t('agenda'),
          }}
        />}
      </div>

      {availableSlots && availableSlots.length > 0 && showSlot && <div>
        <h2 style={{ textAlign: 'center' }} > {t('selectSlot')} </h2>
      </div>}

      <div style={{ display: 'flex', flexDirection: 'row', padding: 20, flexWrap: 'wrap', justifyContent: 'center' }}>
        {availableSlots && availableSlots.length > 0 && showSlot && availableSlots.map(x => {
          return <div className="slots" key={x.start} style={{ cursor: 'pointer', marginRight: 10, minWidth: 296, padding: 10, borderRadius: 7, margin: 10, color: "white" }} onClick={() => handleAvailableSlotClick(x.start)}>
            {x.start}
          </div>
        })}
      </div>

      {selectedSlot && !showSlot && <div>
        <h2 style={{ textAlign: 'center' }} > {t('personalDetails')} </h2>
      </div>}

      <div style={{ display: 'flex', flexDirection: 'row', padding: 20, justifyContent: 'center' }}>
        {selectedSlot && !showSlot && <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }} >
          <TextField autoFocus={true} style={{ paddingTop: 7, width: 296 }} defaultValue={plateNumber} value={plateNumber} onChange={(e) => setPlateNumber(e.target.value.toUpperCase())} label={t('registerationNumber')} variant="outlined" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Button onClick={() => {
            if (plateNumber) {
              setShowCustomerInfo(true);
            } else {
              alert(`Please enter ${t('registerationNumber')}`)
            }
          }} style={{ height: 40, marginTop: 12 }} variant="outlined" > {t('nextButton')} </Button>
        </div>}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        {customer && showCustomerInfor && <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 20, justifyContent: 'center' }} >
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }} >
            <TextField value={businessInforDB.company_details.company_name} style={{ margin: 20, width: 296 }} label={t('companyEmail')} required variant="outlined" />
            <TextField value={businessInforDB.customer_email} style={{ margin: 20, width: 296 }} label={t('email')} type={'email'} required variant="outlined" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }} >
            <Button disabled={callingApi} style={{ margin: 20 }} variant="contained" onClick={() => bookAppointment()} > {t('bookAppointment')} </Button>
          </div>
        </div>}
      </div>


    </div>}

    <Dialog
      fullWidth={true}
      open={showSuccess}
      onClose={() => {
        setShowSuccess(false);
      }}
    >
      <DialogTitle> {t("dialogHeading")} </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br></br>
          {orderResponse && orderResponse.customer &&
            <div><span>{orderResponse.customer.customer_name}</span>
              <p style={{ fontSize: 25, fontWeight: 'bold', margin: 0, padding: 0 }} >{orderResponse.order_packages[0].package.title} {orderResponse.order_extra_services.length > 0 && orderResponse.order_extra_services.map(x => <span> + {x.extra_service.title}</span>)}</p>
              <span style={{ fontSize: 20, fontWeight: 'bold', margin: 0, padding: 0, color: 'gray' }} >{AppConstants.weekDays[new Date(orderResponse.order_date).getDay() - 1]}, {AppConstants.months[new Date(orderResponse.order_date).getMonth()]} {new Date(orderResponse.order_date).getDate()}, {new Date(orderResponse.order_date).getFullYear()}</span>
              <br></br><span style={{ fontSize: 20, fontWeight: 'bold', margin: 0, padding: 0, color: 'gray' }} >{orderResponse.start_time}</span>
              <p><LocationOnIcon style={{ marginBottom: '-5px' }} color='info' height={0.7} /> {orderResponse.car_wash_point_location.name}, {orderResponse.car_wash_point.name}</p>
              <p>{orderResponse.car_wash_point_location.address},</p>
              <p>{cities.find(x => x.id === selectedCity).title}</p>
              <p><PaymentsIcon style={{ marginBottom: '-5px' }} color='info' height={0.7} /> SEK {orderResponse.total_price}</p></div>
          }
          <br></br>
          <Button onClick={() => cancelAppointment(orderResponse.id)} size="small" variant="contained" > {t('cancelBooking')} </Button> &nbsp;&nbsp;
          <Button onClick={() => window.location.reload()} size="small" variant="contained" color='error' > {t('close')} </Button>
          <br></br>
          <div style={{ marginTop: 20 }} >
            <a style={{ textDecoration: 'none' }} href={inviteUrl} download='Invite.ics' ><Button onClick={() => window.location.reload()} size="small" variant="contained" > {t('addToCalendar')} </Button></a>
          </div>
          <br></br>
          <div>
            {orderResponse && orderResponse.event_details && <a rel="noreferrer" style={{ textDecoration: 'none' }} target="_blank" href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${orderResponse.event_details.name}&dates=${orderResponse.event_details.startsAt}/${orderResponse.event_details.endsAt}&location=${orderResponse.event_details.address}&details=${orderResponse.event_details.description}&sf=true&output=xml`} ><Button size="small" variant="contained" > {t('addToGoogleCalendar')} </Button></a>}
          </div>
          <div style={{ marginTop: 20 }} >
            <Button onClick={() => window.location.reload()} size="small" variant="contained" > {t("anotherAppointment")} </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>

    {settings && settings.privacy_policy && <Dialog
      fullWidth={true}
      open={showPrivacyPolicay}
      onClose={() => {
        setShowPrivacyPolicay(false);
      }}
    >
      <DialogTitle> Privacy Policy

        <IconButton
          aria-label="close"
          onClick={() => setShowPrivacyPolicay(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ textAlign: 'initial' }} dangerouslySetInnerHTML={{ __html: settings.privacy_policy }} />
        </DialogContentText>
      </DialogContent>
    </Dialog>}

    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={toggleSnackBar} autoHideDuration={6000} onClose={() => {
      setSeverity('success');
      setToggleSnackBar(false);
      setMessage('');
    }}>
      <Alert onClose={() => {
        setSeverity('success');
        setToggleSnackBar(false);
        setMessage('');
      }} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  </>
}