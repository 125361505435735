import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Container, Typography } from '@mui/material';
import moment from "moment";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DoneIcon from "@mui/icons-material/Done";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PaymentsIcon from "@mui/icons-material/Payments";
import { animateScroll as scroll } from "react-scroll";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";

import {
  cancelOrder,
  getAllCities,

  getAllServices,
  getAllServicesByCarWashPoint,
  getAvailableDatesByCarWash,
  getAvailableSlotsByDateAndPackageAndCarWashPointAndOrderType,
  getCarTypes,
  getCarTypesByServiceAndCarWashPoint,
  getCarWashPointLocationsAPI,
  getCarWashPointsByCityAPI,
  getCustomerDetailsByPhoneNumberAndPlateNumber,
  getExtraServicesBySeriveAndCarWashPointAndCarTypeId,
  getPackagesByMainServiceByCarWashPointByCarType,
  postOrder,
} from "../../services/axios";
import "./private-customer.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AppConstants } from "../../shared";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";

const localizer = momentLocalizer(moment);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const PrivateCustomer = ({ settings }) => {
  let {
    cityid,
    carwashpointid,
    carwashlocationid,
    serviceid,
    cartypeid,
    packageid,
  } = useParams();

  const [cities, setCities] = useState([]);
  const [carWashPoints, setCarWashPoints] = useState([]);
  const [mainServices, setMainServices] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [extraServices, setExtraServices] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCarWashPoint, setCarWashPoint] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedCarWashLocation, setSelectedCarWashLocation] = useState("");
  const [selectedExtraServices, setSelectedExtraServices] = useState([]);
  const [carWashLocations, setCarWashLocations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSlot, setShowSlot] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [plateNumber, setPlateNumber] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    payment_method: "",
    agree: false,
  });
  const [customerExists, setCustomerExists] = useState(false);
  const [showCustomerInfor, setShowCustomerInfo] = useState(false);
  const [packagePrice, setPackagePrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderResponse, setOrderResponse] = useState({});
  const [proceedToCalendar, setproceedToCalendar] = useState(false);
  const [customerType, setCustomerType] = useState("");
  const [inviteUrl, setInviteUrl] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [toggleSnackBar, setToggleSnackBar] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [showPrivacyPolicay, setShowPrivacyPolicay] = useState(false);
  const [callingApi, setCallingApi] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [plateNumberError, setPlateNumberError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailNotValidError, setEmailNotValidError] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [showCustomerInput, setShowCustomerInput] = useState(false);
  const carWashPointsRef = useRef(null);
  const carWashLocationsRef = useRef(null);
  const carWashServicesRef = useRef(null);
  const carTypesRef = useRef(null);
  const carWashPackagesRef = useRef(null);
  const carWashExtraServicesRef = useRef(null);
  const carWashCitiesRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const { t } = useTranslation();

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  try {
    const handleCityChange = async (event) => {
      setSelectedCity(event.target.value);
      setCustomerType("Privatkund");

      await getCarWashPointsByCity(event.target.value);
    };

    const handleCarWashPointChange = async (event) => {
      setCarWashPoint(event.target.value);

      setCustomerType("Privatkund");
      await getMainServices(event.target.value);
      await getCarWashPointLocations(event.target.value);
    };

    const handleCarWashPointLocationChange = async (event) => {
      setSelectedCarWashLocation(event.target.value);
      setCustomerType("Privatkund");

      await getMainServices();
    };

    const handleMainServiceChange = async (event) => {
      setSelectedService(event.target.value);
      setCustomerType("Privatkund");

      await getAllCarTypes(event.target.value);
      if (carTypesRef.current) {
        carTypesRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    const handleCarTypeClick = async (id) => {
      setSelectedCarType(id);

      await getPackages(id);
      await getExtraServices(id);
      if (carWashPackagesRef.current) {
        carWashPackagesRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    const handlePackageChange = async (id) => {
      setSelectedPackage(id);
      setSelectedDate("");
      setSelectedSlot("");
      setAvailableDates([]);
      setAvailableSlots([]);
      setShowCustomerInput(false);
      if (carWashExtraServicesRef.current) {
        carWashExtraServicesRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    const handleExtraServicesClick = async (x) => {
      const selectedExtraServicesCopy = [...selectedExtraServices];
      const priceToAdjust = x.car_types[0].price;

      if (selectedExtraServicesCopy.includes(x.id)) {
        selectedExtraServicesCopy.splice(
          selectedExtraServices.indexOf(x.id),
          1
        );

        setServicePrice(servicePrice - priceToAdjust);
        availableDates.forEach((x) => {
          x.title = packagePrice + (servicePrice - priceToAdjust) + " SEK";
        });
      } else {
        selectedExtraServicesCopy.push(x.id);
        setServicePrice(servicePrice + priceToAdjust);

        availableDates.forEach((x) => {
          x.title = packagePrice + (servicePrice + priceToAdjust) + " SEK";
        });
      }

      setSelectedExtraServices([...selectedExtraServicesCopy]);
      setproceedToCalendar(true);
    };

    const handleAvailableSlotClick = async (slot) => {
      setSelectedSlot(slot);
      setShowSlot(false);
      setShowCustomerInput(true);
    };

    const getCarWashPointsByCity = async (cityId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getCarWashPointsByCityAPI(cityId);


        if (res && res.length) {
          if (res.length === 1) {
            setCarWashPoint(res[0].id);
            await getCarWashPointLocations(res[0].id);
            await getMainServices(res[0].id);
          }
          await setCarWashPoints(res);
          // scroll.scrollToBottom();
        }
        if (carWashPointsRef.current) {
          carWashPointsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getMainServices = async (carWashId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getAllServicesByCarWashPoint(
          carWashId ? carWashId : selectedCarWashPoint
        );

        if (res && res.length) {
          await setMainServices(res);
        }
        if (carWashServicesRef.current) {
          carWashServicesRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getAllCarTypes = async (serviceId, carWashPointId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getCarTypesByServiceAndCarWashPoint(
          serviceId,
          carWashPointId ? carWashPointId : selectedCarWashPoint
        );
        console.log("car types", res)
        if (res && res.length) {
          await setCarTypes(res);
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getPackages = async (carTypeId, serviceId, carWashPointId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getPackagesByMainServiceByCarWashPointByCarType(
          serviceId ? serviceId : selectedService,
          carWashPointId ? carWashPointId : selectedCarWashPoint,
          carTypeId
        );

        if (res && res.packages && res.packages.length) {
          await setPackages(res.packages);
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getExtraServices = async (carTypeId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getExtraServicesBySeriveAndCarWashPointAndCarTypeId(
          selectedService,
          selectedCarWashPoint,
          carTypeId
        );

        if (res && res.extra_services && res.extra_services.length) {
          await setExtraServices(res.extra_services);
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getAvailableDates = async (packagePrice, carwashPoint = "") => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getAvailableDatesByCarWash(
          carwashPoint ? carwashPoint : selectedCarWashPoint
        );

        if (res && res.available_dates && res.available_dates.length) {
          res.available_dates = res.available_dates.map((x) => {
            return {
              allDay: false,
              title: packagePrice + servicePrice + "Kr",
              start: new Date(x),
              end: new Date(x),
              resource: "Gmail",
              isHoliday: false,
            };
          });

          if (res && res.holidays && res.holidays.length) {
            console.log(res.holidays);
            res.holidays.map((x) => {
              res.available_dates.push({
                allDay: true,
                title: x.name,
                start: new Date(x.date),
                end: new Date(x.date),
                resource: "Gmail",
                class: "abc",
                className: "abc",
                isHoliday: true,
              });
            });
          }

          setAvailableDates(res.available_dates);

          scroll.scrollToBottom();
        }
      } catch (error) {
        console.log(error, "error");

        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());
      }
    };

    const getAvailablSlots = async (date) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const dateCopy = new Date(date);
        const dateToServer =
          dateCopy.getFullYear() +
          "-" +
          (dateCopy.getMonth() + 1) +
          "-" +
          dateCopy.getDate();

        const res =
          await getAvailableSlotsByDateAndPackageAndCarWashPointAndOrderType(
            dateToServer,
            selectedPackage,
            selectedCarWashPoint,
            "b2c",
            selectedCarType
          );

        if (
          res &&
          res.available_time_slots &&
          res.available_time_slots.length
        ) {
          setAvailableSlots(res.available_time_slots);

          scroll.scrollToBottom();
        }
      } catch (error) {
        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());

        console.log(error, "error");
      }
    };

    const getCustomerDetails = async () => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        if (phoneNumber) {
          setPhoneNumberError(false);
        }

        if (plateNumber) {
          setPlateNumberError(false);
        }

        if (phoneNumber && plateNumber) {
          setPhoneNumberError(false);
          setPlateNumberError(false);
          const res = await getCustomerDetailsByPhoneNumberAndPlateNumber(
            phoneNumber,
            plateNumber
          );

          if (res && res.customer) {
            setSeverity("success");
            setToggleSnackBar(true);
            setMessage(t("fetchedUserDetails"));

            setCustomer({
              name: res.customer.customer_name,
              email: res.customer.customer_email,
              payment_method: "",
            });
            setCustomerExists(true);
          } else {
            setCustomer({
              name: "",
              email: "",
              payment_method: "",
            });
            setCustomerExists(false);
          }

          setShowCustomerInfo(true);
          scroll.scrollToBottom();
        }

        if (!phoneNumber) {
          setPhoneNumberError(true);

          setSeverity("error");
          setToggleSnackBar(true);
          setMessage(t("requiredPhoneNumberField"));
        }

        if (!plateNumber) {
          setPlateNumberError(true);
        }
      } catch (error) {
        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());

        console.log(error, "error");
      }
    };

    const getCarWashPointLocations = async (carWashPointId) => {
      try {
        setSeverity("success");
        setToggleSnackBar(false);
        setMessage("");

        const res = await getCarWashPointLocationsAPI(carWashPointId);

        if (res && res.locations && res.locations.length === 1) {
          setSelectedCarWashLocation(res.locations[0].id);
        }

        await setCarWashLocations(res.locations);
        console.log(res.locations);

        if (carWashLocationsRef.current) {
          carWashLocationsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        setSeverity("error");
        setToggleSnackBar(true);
        setMessage(error.toString());

        console.log(error, "error");
      }
    };

    const bookAppointment = async () => {
      setSeverity("success");
      setToggleSnackBar(false);
      setMessage("");
      setCallingApi(true);

      if (customer.name) {
        setNameError(false);
      }

      if (!customer.name) {
        setNameError(true);
      }

      if (customer.email) {
        if (!validateEmail(customer.email)) {
          setEmailNotValidError(true);
        } else {
          setEmailNotValidError(false);
        }

        setEmailError(false);
      }

      if (!customer.email) {
        setEmailError(true);
      }

      if (customer.agree) {
        setAgreeError(false);
      }

      if (!customer.agree) {
        setAgreeError(true);
      }

      if (customer.name && customer.email && customer.agree) {
        const dateCopy = new Date(selectedDate);
        const dateToServer =
          dateCopy.getFullYear() +
          "-" +
          (dateCopy.getMonth() + 1) +
          "-" +
          dateCopy.getDate();
        const body = {
          city_id: selectedCity,
          car_type_id: selectedCarType,
          service_id: selectedService,
          car_wash_point_id: selectedCarWashPoint,
          payment_method: "pay_at_place",
          car_wash_location_id: selectedCarWashLocation,
          is_b2c: true,
          is_b2b: false,
          date: dateToServer,
          total_price: packagePrice + servicePrice,
          customer_details: {
            b2b_customer_id: "",
            email: customer.email,
            name: customer.name,
            phone_number: phoneNumber,
            car_plate_number: plateNumber,
          },
          order_items: {
            packages: [],
            extra_services: [],
          },
        };

        if (selectedPackage) {
          const objPackageToSend = {
            package_id: selectedPackage,
            car_type: selectedCarType,
            date: dateToServer,
            start_time: selectedSlot,
            price: packagePrice,
            quantity: 1,
          };

          body.order_items.packages.push(objPackageToSend);
        }

        if (selectedExtraServices && selectedExtraServices.length) {
          selectedExtraServices.forEach((x) => {
            const extraService = extraServices.find((y) => y.id === x);
            const objExtraServicesToSend = {
              extra_service_id: x,
              car_type: selectedCarType,
              date: dateToServer,
              price: extraService.car_types[0].price,
              quantity: 1,
            };

            body.order_items.extra_services.push(objExtraServicesToSend);
          });
        }

        try {
          const res = await postOrder(body);

          if (res && res.order) {
            setOrderResponse(res.order);
            prepareIcsFile(
              res.order.event_details.startsAt,
              res.order.event_details.endsAt,
              "Car Service Booking",
              res.order.event_details.address,
              res.order.event_details.address
            );
            setShowSuccess(true);
            setCallingApi(false);
          }
        } catch (error) {
          setSeverity("error");
          setToggleSnackBar(true);
          setMessage(error.toString());
          setCallingApi(false);

          console.log(error, "error");
        }
      }

      setCallingApi(false);
    };

    const cancelAppointment = async (id) => {
      setSeverity("success");
      setToggleSnackBar(false);
      setMessage("");

      const decision = window.confirm(
        "Are you sure you want to cancel this booking."
      );

      if (decision) {
        const body = {
          order_id: id,
        };

        try {
          const res = await cancelOrder(body);

          if (res && res.message) {
            setSeverity("success");
            setToggleSnackBar(true);
            setMessage(res.message);

            window.location.reload();
          }
        } catch (error) {
          console.log(error, "error");

          setSeverity("error");
          setToggleSnackBar(true);
          setMessage(error.toString());
        }
      } else {
        window.location.reload();
      }
    };

    const prepareIcsFile = (
      dateStart,
      dateEnd,
      summary,
      description,
      location
    ) => {
      const content = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:car-wash-system
BEGIN:VTIMEZONE
TZID:Europe/Stockholm
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
END:STANDARD
BEGIN:DAYLIGHT
DTSTART:${dateStart}
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
END:DAYLIGHT
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
END:STANDARD
END:VTIMEZONE
BEGIN:VTIMEZONE
TZID:UTC
BEGIN:STANDARD
DTSTART:${dateStart}
TZOFFSETFROM:+0000
TZOFFSETTO:+0000
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${dateStart}
SUMMARY:${summary}
DESCRIPTION:${description}
DTSTART;TZID=Europe/Stockholm:${dateStart}
DTEND;TZID=Europe/Stockholm:${dateEnd}
END:VEVENT
END:VCALENDAR
LOCATION:${location}`;

      var data = new File([content], { type: "text/plain" });

      const icsFile = window.URL.createObjectURL(data);

      setInviteUrl(icsFile);

      return icsFile;
    };

    useEffect(() => {
      try {
        (async () => {
          if (
            cityid &&
            carwashpointid &&
            carwashlocationid &&
            serviceid &&
            cartypeid &&
            packageid
          ) {
            if (
              isNaN(Number(cityid)) ||
              isNaN(Number(carwashpointid)) ||
              isNaN(Number(carwashlocationid)) ||
              isNaN(Number(serviceid)) ||
              isNaN(Number(cartypeid)) ||
              isNaN(Number(packageid))
            ) {
              setSeverity("error");
              setToggleSnackBar(true);
              setMessage(
                "Something went wrong with the URL, please try with valid one."
              );
            } else {
              try {
                if (!isNaN(cityid)) {
                  await getCarWashPointsByCity(Number(cityid));
                  await getCarWashPointLocations(Number(carwashpointid));
                  await getMainServices(Number(carwashpointid));
                  await getAllCarTypes(
                    Number(serviceid),
                    Number(carwashpointid)
                  );
                  const packagesGot = await getPackages(
                    Number(cartypeid),
                    Number(serviceid),
                    Number(carwashpointid)
                  );

                  const res = await getAllCities();

                  if (res && res.length) {
                    setCities(res);
                  }

                  setSelectedCity(Number(cityid));
                  setCarWashPoint(Number(carwashpointid));
                  setSelectedCarWashLocation(Number(carwashlocationid));
                  setSelectedService(Number(serviceid));
                  setSelectedCarType(Number(cartypeid));
                  setSelectedPackage(Number(packageid));
                  setCustomerType("Privatkund");

                  if (packagesGot && packagesGot.length) {
                    const packageDetails = packagesGot.find(
                      (x) => x.id === Number(packageid)
                    );

                    setPackagePrice(packageDetails.price);
                    setproceedToCalendar(true);
                    setShowCalendar(false);

                    if (extraServices && extraServices.length === 0) {
                      setShowCalendar(true);
                      setproceedToCalendar(false);
                      await getAvailableDates(
                        packageDetails.price,
                        carwashpointid
                      );
                    }
                  }
                }
              } catch (err) {
                alert("Something wrong with the link");

                window.location.replace("/");
              }
            }
          } else {
            const res = await getAllCities();

            if (res && res.length) {
              if (res.length === 1) {
                setSelectedCity(res[0].id);

                await getCarWashPointsByCity(res[0].id);
              }

              await setCities(res);
              if (carWashCitiesRef.current) {
                carWashCitiesRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }
          }
        })();
      } catch (error) {
        console.log(error, "error");
      }
    }, []);

    return (
      <>
        {!showSuccess && (
          <div style={{ justifyContent: "center" }}>
            {!customerType && (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                }}
              >
                <h2 style={{ textAlign: "center" }}>
                  {" "}
                  {t("selectCustomerType")}{" "}
                </h2>
              </div>
            )}
            {!customerType && (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                }}
              >
                <Card
                  variant="outlined"
                  onClick={() => {
                    setCustomerType("Privatkund");
                  }}
                  className={
                    customerType === "Privatkund" || customerType === ""
                      ? "selected_customer_type"
                      : "customer_type"
                  }
                  style={{ marginRight: 20 }}
                >
                  <CardContent>
                    <h4 style={{ color: "#0288d1" }}>
                      {" "}
                      {t("privateCustomer")}{" "}
                    </h4>
                  </CardContent>
                </Card>
                <Link
                  to="/business-customer"
                  style={{ color: "#0288d1", textDecoration: "none" }}
                >
                  <Card
                    onClick={() => {
                      setCustomerType("Företagskund");
                    }}
                    className={
                      customerType === "Företagskund"
                        ? "selected_customer_type"
                        : "customer_type"
                    }
                    variant="outlined"
                  >
                    <CardContent>
                      <h4 style={{ color: "#0288d1", textDecoration: "none" }}>
                        {" "}
                        {t("businessCustomer")}{" "}
                      </h4>
                    </CardContent>
                  </Card>
                </Link>
              </div>
            )}

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                padding: 20,
                justifyContent: "center",
              }}
            >
              {customerType && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectCustomerType")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                    </span>
                  </div>
                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {customerType}{" "}
                  </p>
                </div>
              )}

              {selectedCity && cities.length > 1 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedCity")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCity("");
                        setCarWashPoint("");
                        setSelectedCarWashLocation("");
                        setSelectedService("");
                        setSelectedCarType("");
                        setSelectedPackage("");
                        setExtraServices([]);
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setServicePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setMainServices([]);
                        setCarTypes([]);
                        setPackages([]);
                        setExtraServices([]);
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {cities.find((x) => x.id === selectedCity).title}
                  </p>
                </div>
              )}

              {selectedCarWashPoint && carWashPoints.length > 1 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p>{t("selectedCarWashPoint")}</p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCarWashPoint("");
                        setSelectedCarWashLocation("");
                        setSelectedService("");
                        setSelectedCarType("");
                        setSelectedPackage("");
                        setExtraServices([]);
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setServicePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setMainServices([]);
                        setCarTypes([]);
                        setPackages([]);
                        setExtraServices([]);
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {
                      carWashPoints.find((x) => x.id === selectedCarWashPoint)
                        .name
                    }
                  </p>
                </div>
              )}

              {selectedCarWashLocation && carWashLocations.length > 1 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedCarWashLocation")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCarWashLocation("");
                        setSelectedService("");
                        setSelectedCarType("");
                        setSelectedPackage("");
                        setExtraServices([]);
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setServicePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setMainServices([]);
                        setCarTypes([]);
                        setPackages([]);
                        setExtraServices([]);
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {
                      carWashLocations.find(
                        (x) => x.id === selectedCarWashLocation
                      ).name
                    }
                  </p>
                </div>
              )}

              {selectedService && mainServices.length > 0 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedService")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        setSelectedService("");
                        setSelectedCarType("");
                        setSelectedPackage("");
                        setExtraServices([]);
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setServicePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setCarTypes([]);
                        setPackages([]);
                        setExtraServices([]);
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);

                        await getMainServices();
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {mainServices.find((x) => x.id === selectedService).name}
                  </p>
                </div>
              )}

              {selectedCarType && carTypes.length > 0 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedCarType")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        setSelectedCarType("");
                        setSelectedPackage("");
                        setExtraServices([]);
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setServicePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setPackages([]);
                        setExtraServices([]);
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);

                        await getCarTypes();
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {carTypes.find((x) => x.id === selectedCarType).name}
                  </p>
                </div>
              )}

              {selectedPackage && packages.length > 0 && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedPackage")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedPackage("");
                        setSelectedDate("");
                        setSelectedSlot("");
                        setPhoneNumber("");
                        setPlateNumber("");
                        setSelectedSlot("");
                        setCustomer({
                          name: "",
                          email: "",
                          payment_method: "",
                        });
                        setCustomerExists(false);
                        setShowCustomerInfo(false);
                        setPackagePrice(0);
                        setShowSuccess(false);
                        setOrderResponse({});
                        setAvailableDates([]);
                        setAvailableSlots([]);
                        setproceedToCalendar(false);
                        setShowCalendar(false);
                        setShowCustomerInput(false);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p className="selected_date" onClick={() => { }}>
                    {" "}
                    {packages.find((x) => x.id === selectedPackage).title}
                  </p>
                </div>
              )}

              {selectedExtraServices.length > 0 &&
                (showCalendar || selectedDate) &&
                extraServices.length > 0 && (
                  <div style={{ width: 296, marginRight: 30 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <p> {t("selectedExtraServices")} </p>{" "}
                      <span
                        style={{
                          marginTop: 15,
                          marginLeft: 77,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedDate("");
                          setSelectedSlot("");
                          setPhoneNumber("");
                          setPlateNumber("");
                          setCustomer({
                            name: "",
                            email: "",
                            payment_method: "",
                          });
                          setCustomerExists(false);
                          setShowCustomerInfo(false);
                          setShowSuccess(false);
                          setOrderResponse({});
                          setAvailableDates([]);
                          setAvailableSlots([]);
                          setproceedToCalendar(true);
                          setSelectedExtraServices([]);
                          setShowCalendar(false);
                          setServicePrice(0);
                          setShowCustomerInput(false);
                        }}
                      >
                        {" "}
                        <EditIcon color="info" />{" "}
                      </span>
                    </div>

                    <p
                      className="selected_date"
                      style={{ fontSize: 15, marginTop: 25 }}
                      onClick={() => { }}
                    >
                      {" "}
                      {selectedExtraServices.map((x) => (
                        <span onClick={() => { }}>
                          {" "}
                          {extraServices.find((y) => y.id === x).title},{" "}
                        </span>
                      ))}
                    </p>
                  </div>
                )}

              {selectedDate && (
                <div style={{ width: 296, marginRight: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedDate")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedDate(null);
                        setShowCalendar(true);
                        setAvailableSlots([]);
                        setShowSlot(false);
                        setSelectedSlot(null);
                        setShowCustomerInput(false);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p
                    className="selected_date"
                    onClick={() => {
                      setSelectedDate(null);
                      setShowCalendar(true);
                      setAvailableSlots([]);
                      setShowSlot(false);
                      setSelectedSlot(null);
                      setShowCustomerInput(false);
                    }}
                  >
                    {" "}
                    {AppConstants.weekDays[selectedDate.getDay() - 1]},{" "}
                    {AppConstants.months[new Date(selectedDate).getMonth()]}{" "}
                    {new Date(selectedDate).getDate()},{" "}
                    {new Date(selectedDate).getFullYear()}{" "}
                  </p>
                </div>
              )}

              {selectedSlot && (
                <div style={{ width: 296 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <p> {t("selectedSlot")} </p>{" "}
                    <span
                      style={{
                        marginTop: 15,
                        marginLeft: 77,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedSlot(null);
                        setShowSlot(true);
                      }}
                    >
                      {" "}
                      <EditIcon color="info" />{" "}
                    </span>
                  </div>

                  <p
                    className="selected_date"
                    onClick={() => {
                      setSelectedSlot(null);
                      setShowSlot(true);
                    }}
                  >
                    {" "}
                    {selectedSlot}
                  </p>
                </div>
              )}
            </div>

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div ref={carWashCitiesRef}>
                {cities && cities.length > 1 && !selectedCity && (
                  <Box
                    style={{ padding: 20, justifyContent: "center" }}
                    sx={{ minWidth: 300 }}
                  >
                    <FormControl
                      color="info"
                      size="medium"
                      variant="filled"
                      style={{ width: 300 }}
                    >
                      <InputLabel> {t("selectCity")} </InputLabel>
                      <Select value={selectedCity} onChange={handleCityChange}>
                        {cities.map((x) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </div>

              <div ref={carWashPointsRef}>
                {carWashPoints &&
                  carWashPoints.length > 1 &&
                  !selectedCarWashPoint && (
                    <Box
                      style={{ padding: 20, justifyContent: "center" }}
                      sx={{ minWidth: 300 }}
                    >
                      <FormControl
                        color="info"
                        size="medium"
                        variant="filled"
                        style={{ width: 300 }}
                      >
                        <InputLabel> {t("selectCarWashPoint")} </InputLabel>
                        <Select
                          value={selectedCarWashPoint}
                          onChange={handleCarWashPointChange}
                        >
                          {carWashPoints.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                              {x.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
              </div>

              <div ref={carWashLocationsRef}>
                {carWashLocations &&
                  carWashLocations.length > 1 &&
                  !selectedCarWashLocation && (
                    <Box
                      style={{ padding: 20, justifyContent: "center" }}
                      sx={{ minWidth: 300 }}
                    >
                      <FormControl
                        color="info"
                        size="medium"
                        variant="filled"
                        style={{ width: 300 }}
                      >
                        <InputLabel> {t("selectCarWashLocation")} </InputLabel>
                        <Select
                          value={selectedCarWashLocation}
                          onChange={handleCarWashPointLocationChange}
                        >
                          {carWashLocations.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                              {x.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
              </div>

              <div ref={carWashServicesRef}>
                {mainServices &&
                  mainServices.length > 0 &&
                  !selectedService &&
                  selectedCarWashLocation && (
                    <Box
                      style={{ padding: 20, justifyContent: "center" }}
                      sx={{ minWidth: 300 }}
                    >
                      <FormControl
                        color="info"
                        size="medium"
                        variant="filled"
                        style={{ width: 300 }}
                      >
                        <InputLabel> {t("selectServiceType")} </InputLabel>
                        <Select
                          value={selectedService}
                          onChange={handleMainServiceChange}
                        >
                          {mainServices.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                              {x.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
              </div>
            </div>

            {carTypes && carTypes.length > 0 && !selectedCarType && (
              <div>
                <h2 style={{ textAlign: "center" }}> {t("selectCarType")} </h2>
              </div>
            )}

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              ref={carTypesRef}
            >
              {carTypes &&
                carTypes.length > 0 &&
                !selectedCarType &&
                carTypes.map((x) => {
                  return (
                    <span
                      key={x.id}
                      className={
                        x.id === selectedCarType
                          ? "selected_car_type"
                          : "car_type"
                      }
                      onClick={() => handleCarTypeClick(x.id)}
                    >
                      <img
                        alt={x.name}
                        src={x.image_path}
                        className="car_image"
                      />
                      <span className="caption">{x.name}</span>
                    </span>
                  );
                })}
            </div>
            <Container>
              {/* <Typography variant="h1" component="h1" gutterBottom>
        Welcome to Car Wash Subscription
      </Typography>
      <Button variant="contained" onClick={handleOpenModal}>
        Choose Subscription Plan
      </Button>
      <SubscriptionPlan isOpen={isModalOpen} onClose={handleCloseModal} /> */}
            </Container>
            {packages && packages.length > 0 && !selectedPackage && (
              <div>
                <h2 style={{ textAlign: "center" }}> {t("selectPackage")} </h2>
              </div>
            )}

            <Box ref={carWashPackagesRef}>
              <Slider
                {...sliderSettings}
                style={{ margin: "auto", width: "90%" }}
              >
                {packages &&
                  packages.length > 0 &&
                  !selectedPackage &&
                  packages.map((x) => (
                    <div style={{ padding: 5 }}>
                      <div
                        key={x.title}
                        className={
                          x.id === selectedPackage
                            ? "selected_package"
                            : "package"
                        }
                        onClick={async () => {
                          handlePackageChange(x.id);
                          setPackagePrice(x.price);
                          setproceedToCalendar(true);
                          setShowCalendar(false);

                          if (extraServices && extraServices.length === 0) {
                            setShowCalendar(true);
                            setproceedToCalendar(false);
                            await getAvailableDates(x.price);
                          }
                        }}
                        style={{
                          marginLeft: 15,
                          textAlign: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        variant="outlined"
                      >
                        <div>
                          <h2 style={{ color: "#0288d1" }}>{x.title}</h2>
                          <h2 style={{ color: "#0288d1", margin: 0 }}>
                            {t("price")}: {x.price}Kr.{" "}
                          </h2>
                          <h4 style={{ color: "#0288d1", margin: "5px" }}>
                            {x.car_type_description}
                          </h4>
                          <span
                            style={{ color: "#0288d1", fontWeight: "bold" }}
                          >
                            {t("duration")}: {x.duration} {t("mins")}
                          </span>

                          <br></br>
                          {x.description &&
                            x.description.length > 0 &&
                            x.description.map((s) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: 5,
                                  }}
                                >
                                  <DoneIcon
                                    color="info"
                                    style={{ marginRight: 10, fontSize: 30 }}
                                  />
                                  <div
                                    style={{ textAlign: "initial" }}
                                    dangerouslySetInnerHTML={{ __html: s }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <div
                          style={{ justifyContent: "center", marginBottom: 10 }}
                        >
                          <Button
                            size="small"
                            color="success"
                            variant="contained"
                          >
                            {" "}
                            {t("select")}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </Box>

            {extraServices &&
              extraServices.length > 0 &&
              !showCalendar &&
              !selectedDate && (
                <div>
                  <h2 style={{ textAlign: "center" }}>
                    {" "}
                    {t("selectExtraServices")}{" "}
                  </h2>
                </div>
              )}

            <Box ref={carWashExtraServicesRef}>
              <Slider {...sliderSettings} style={{ margin: "auto" }}>
                {extraServices &&
                  extraServices.length > 0 &&
                  !showCalendar &&
                  !selectedDate &&
                  extraServices.map((x) => (
                    <div style={{ padding: 5 }}>
                      <div
                        key={x.id}
                        className={
                          selectedExtraServices.includes(x.id)
                            ? "selected_service"
                            : "service"
                        }
                        onClick={() => handleExtraServicesClick(x)}
                        variant="outlined"
                        style={{
                          marginLeft: 15,
                          textAlign: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div>
                          {x.icon_path && (
                            <img
                              src={x.icon_path}
                              alt="service_logo"
                              style={{ maxWidth: 100, maxHeight: 100 }}
                            />
                          )}
                          <h2 style={{ color: "#0288d1" }}>{x.title}</h2>
                          <h2 style={{ color: "#0288d1", margin: 0 }}>
                            {t("price")}: {x.price} {x.car_types[0].price}Kr.{" "}
                          </h2>
                          {x.description && (
                            <div
                              style={{ textAlign: "center" }}
                              dangerouslySetInnerHTML={{
                                __html: x.description,
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            justifyContent: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Button
                            size="small"
                            color={
                              selectedExtraServices.includes(x.id)
                                ? "info"
                                : "success"
                            }
                            variant="contained"
                          >
                            {" "}
                            {selectedExtraServices.includes(x.id)
                              ? t("remove")
                              : t("select")}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </Box>

            {proceedToCalendar && !selectedDate && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 20,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  onClick={async () => {
                    setShowCalendar(true);
                    setproceedToCalendar(false);
                    await getAvailableDates(packagePrice);
                  }}
                  color="info"
                  variant="contained"
                >
                  {" "}
                  {t("nextButton")}{" "}
                </Button>
              </div>
            )}

            {showCalendar && (
              <div>
                <h2 style={{ textAlign: "center" }}>
                  {" "}
                  {t("calendarHeading")}{" "}
                </h2>
              </div>
            )}

            <div className="calendar_div">
              {showCalendar && (
                <Calendar
                  localizer={localizer}
                  events={availableDates}
                  style={{ height: 500 }}
                  onSelectEvent={async (e) => {
                    if (!e.allDay) {
                      setSelectedDate(e.start);
                      if (selectedPackage) {
                        await getAvailablSlots(e.start);
                        setShowSlot(true);
                      } else {
                        setShowCustomerInput(true);
                      }
                      setShowCalendar(false);
                    } else {
                      alert(`It's a holiday due in account of ${e.title}`);
                    }
                  }}
                  messages={{
                    day: t("day"),
                    week: t("week"),
                    month: t("month"),
                    previous: t("previous"),
                    next: t("next"),
                    yesterday: t("yesterday"),
                    tomorrow: t("tomorrow"),
                    today: t("today"),
                    agenda: t("agenda"),
                  }}
                />
              )}
            </div>

            {availableSlots && availableSlots.length > 0 && showSlot && (
              <div>
                <h2 style={{ textAlign: "center" }}> {t("selectSlot")} </h2>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 20,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {availableSlots &&
                availableSlots.length > 0 &&
                showSlot &&
                availableSlots.map((x) => {
                  return (
                    <div
                      className="slots"
                      key={x.start}
                      style={{
                        cursor: "pointer",
                        marginRight: 10,
                        minWidth: 296,
                        padding: 10,
                        borderRadius: 7,
                        margin: 10,
                      }}
                      onClick={() => handleAvailableSlotClick(x.start)}
                    >
                      {x.start}
                    </div>
                  );
                })}
            </div>

            {showCustomerInput && (
              <div>
                <h2 style={{ textAlign: "center" }}>
                  {" "}
                  {t("personalDetails")}{" "}
                </h2>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                padding: 20,
                justifyContent: "center",
              }}
            >
              {showCustomerInput && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={{
                      paddingTop: 7,
                      width: 296,
                      textTransform: "uppercase",
                      fontSize: 16,
                    }}
                    defaultValue={plateNumber}
                    value={plateNumber}
                    onChange={(e) => {
                      setPlateNumber(e.target.value.toUpperCase());
                      setCustomer({
                        ...customer,
                        name: "",
                        email: "",
                      });
                      setShowCustomerInfo(false);
                    }}
                    placeholder={t("registerationNumber")}
                    variant="outlined"
                    autoFocus={true}
                    error={plateNumberError}
                    helperText={plateNumberError ? t("requiredField") : ""}
                  />
                  <PhoneInput
                    style={{ height: 70, width: 296 }}
                    className="phone_number"
                    defaultCountry="SE"
                    placeholder={t("telephoneNumber")}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e);
                      setCustomer({
                        ...customer,
                        name: "",
                        email: "",
                      });
                      setShowCustomerInfo(false);
                    }}
                    limitMaxLength={true}
                  />
                  <br></br>
                </div>
              )}
            </div>

            {showCustomerInput && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingLeft: 20,
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={getCustomerDetails}
                  style={{ marginTop: 13 }}
                  variant="contained"
                >
                  {" "}
                  {t("getDetails")}
                </Button>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                padding: 20,
                justifyContent: "center",
              }}
            >
              {customer && showCustomerInfor && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      bookAppointment();
                    }}
                  >
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        value={customer.name}
                        style={{
                          margin: 20,
                          marginLeft: 0,
                          width: 296,
                          background: "white",
                        }}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            name: e.target.value,
                          })
                        }
                        label={t("name")}
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? t("requiredField") : ""}
                      />
                      <TextField
                        value={customer.email}
                        style={{
                          margin: 20,
                          marginLeft: 0,
                          width: 296,
                          background: "white",
                        }}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            email: e.target.value,
                          })
                        }
                        label={t("email")}
                        variant="outlined"
                        error={emailError || emailNotValidError}
                        helperText={
                          emailError || emailNotValidError
                            ? emailError
                              ? t("requiredField")
                              : t("emailNotValid")
                            : ""
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 20,
                          paddingBottom: 0,
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={customer.agree}
                              onClick={(e) => {
                                setCustomer({
                                  ...customer,
                                  agree: e.target.checked,
                                });
                              }}
                            />
                          }
                          label={``}
                        />{" "}
                        <span style={{ marginTop: 7 }}>
                          {" "}
                          {t("agreePP")} {settings.company_name.trim()}
                          {t("s")}{" "}
                          <span
                            onClick={() => {
                              setShowPrivacyPolicay(true);
                            }}
                            style={{
                              color: "#0288d1",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            {t("PPTitle")}.
                          </span>{" "}
                        </span>
                      </div>
                      {agreeError && <p>{t("fillCheckBox")}</p>}
                    </FormControl>
                    <Button
                      disabled={callingApi}
                      style={{ margin: 20, marginLeft: 0 }}
                      type="submit"
                      variant="contained"
                    >
                      {" "}
                      {t("bookAppointment")}{" "}
                    </Button>
                  </form>
                </div>
              )}
            </div>
          </div>
        )}

        <Dialog
          fullWidth={true}
          open={showSuccess}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <DialogTitle> {t("dialogHeading")} </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <br></br>
              {orderResponse && orderResponse.customer && (
                <div>
                  <span>{orderResponse.customer.customer_name}</span>
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {orderResponse.order_packages.length > 0 &&
                      orderResponse.order_packages[0].package.title}{" "}
                    {orderResponse.order_extra_services.length > 0 &&
                      orderResponse.order_extra_services.map((x) => (
                        <span> + {x.extra_service.title}</span>
                      ))}
                  </p>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      margin: 0,
                      padding: 0,
                      color: "gray",
                    }}
                  >
                    {
                      AppConstants.weekDays[
                      new Date(orderResponse.order_date).getDay() - 1
                      ]
                    }
                    ,{" "}
                    {
                      AppConstants.months[
                      new Date(orderResponse.order_date).getMonth()
                      ]
                    }{" "}
                    {new Date(orderResponse.order_date).getDate()},{" "}
                    {new Date(orderResponse.order_date).getFullYear()}
                  </span>
                  <br></br>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      margin: 0,
                      padding: 0,
                      color: "gray",
                    }}
                  >
                    {orderResponse.start_time}
                  </span>
                  <p>
                    <LocationOnIcon
                      style={{ marginBottom: "-5px" }}
                      color="info"
                      height={0.7}
                    />{" "}
                    {orderResponse.car_wash_point_location.name},{" "}
                    {orderResponse.car_wash_point.name}
                  </p>
                  <p>{orderResponse.car_wash_point_location.address},</p>
                  <p>{cities.find((x) => x.id === selectedCity).title}</p>
                  <p>
                    <PaymentsIcon
                      style={{ marginBottom: "-5px" }}
                      color="info"
                      height={0.7}
                    />{" "}
                    SEK {orderResponse.total_price}
                  </p>
                </div>
              )}
              <br></br>
              <Button
                onClick={() => cancelAppointment(orderResponse.id)}
                size="small"
                variant="contained"
              >
                {" "}
                {t("cancelBooking")}{" "}
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                onClick={() => window.location.reload()}
                size="small"
                variant="contained"
                color="error"
              >
                {" "}
                {t("close")}{" "}
              </Button>
              <br></br>
              <div style={{ marginTop: 20 }}>
                <a
                  style={{ textDecoration: "none" }}
                  href={inviteUrl}
                  download="Invite.ics"
                >
                  <Button
                    onClick={() => window.location.reload()}
                    size="small"
                    variant="contained"
                  >
                    {" "}
                    {t("addToCalendar")}{" "}
                  </Button>
                </a>
              </div>
              <br></br>
              <div>
                {orderResponse && orderResponse.event_details && (
                  <a
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${orderResponse.event_details.name}&dates=${orderResponse.event_details.startsAt}/${orderResponse.event_details.endsAt}&location=${orderResponse.event_details.address}&details=${orderResponse.event_details.description}&sf=true&output=xml`}
                  >
                    <Button size="small" variant="contained">
                      {" "}
                      {t("addToGoogleCalendar")}{" "}
                    </Button>
                  </a>
                )}
              </div>
              <div style={{ marginTop: 20 }}>
                <Button
                  onClick={() => window.location.reload()}
                  size="small"
                  variant="contained"
                >
                  {" "}
                  {t("anotherAppointment")}{" "}
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {settings && settings.privacy_policy && (
          <Dialog
            fullWidth={true}
            open={showPrivacyPolicay}
            onClose={() => {
              setShowPrivacyPolicay(false);
            }}
          >
            <DialogTitle>
              {" "}
              Privacy Policy
              <IconButton
                aria-label="close"
                onClick={() => setShowPrivacyPolicay(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div
                  style={{ textAlign: "initial" }}
                  dangerouslySetInnerHTML={{ __html: settings.privacy_policy }}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toggleSnackBar}
          autoHideDuration={6000}
          onClose={() => {
            setSeverity("success");
            setToggleSnackBar(false);
            setMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setSeverity("success");
              setToggleSnackBar(false);
              setMessage("");
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  } catch (error) {
    return (
      <>
        {console.log(error, "error")}
        <Alert onClose={() => { }} severity="error" sx={{ width: "100%" }}>
          Something Went Wrong with your URL. Please try with valid.
        </Alert>
      </>
    );
  }
};
